<template class="p-d-flex p-flex-column filter-container">
  <div class="p-grid header">
    <h4 class="p-col-8 p-sm-7 p-lg-8">Edit Preference</h4>
    <div class="p-col-4 p-sm-5 p-lg-4 p-r-1 p-d-flex p-jc-end">
      <Button
        :label="screenSize > 450 ? 'Close' : null"
        icon="pi pi-times"
        icon-pos="left"
        @click="close"
      />
    </div>
  </div>
  <form class="p-d-flex p-flex-column base" @submit.prevent="apply()">
    <div class="p-d-flex p-flex-column">
      <div class="p-grid p-p-2">
        <label>Religion</label>
        <div class="p-col-12 p-pt-3 p-d-flex p-flex-column">
          <MultiSelect
            v-model="filter.religion"
            :filter="true"
            :options="selectOptions.religions"
            option-label="display"
            option-value="value"
            display="chip"
          />
        </div>
      </div>
      <div class="p-grid p-p-2">
        <label class="p-pl-0">Age</label>
        <div class="p-col-12 p-d-flex p-p-0">
          <div class="p-col-6">
            <p class="p-col-12">From</p>
            <InputText v-model.number="filter.age_from" class="p-col-12" />
          </div>
          <div class="p-col-6">
            <p class="p-col-12">To</p>
            <InputText v-model.number="filter.age_to" class="p-col-12" />
          </div>
        </div>
      </div>
      <div class="p-grid p-p-2">
        <label class="p-pl-0">Height</label>
        <div class="p-col-12 p-d-flex p-p-0">
          <div class="p-col-6">
            <p class="p-col-12">From</p>
            <InputText v-model.number="filter.height_from" class="p-col-12" />
          </div>
          <div class="p-col-6">
            <p class="p-col-12">To</p>
            <InputText v-model.number="filter.height_to" class="p-col-12" />
          </div>
        </div>
      </div>
      <div class="p-grid p-p-2">
        <label>Mother Tongue</label>
        <div class="p-col-12 p-pt-3 p-d-flex p-flex-column">
          <MultiSelect
            v-model="filter.mother_tongue"
            :options="selectOptions.mother_tongues"
            :filter="true"
            option-label="display"
            option-value="value"
            display="chip"
          />
        </div>
      </div>
      <div class="p-grid p-p-2">
        <label>Education</label>
        <div class="p-col-12 p-pt-3 p-d-flex p-flex-column">
          <Dropdown
            v-model="filter.highest_qualification"
            :options="selectOptions.educations"
            option-label="display"
            option-value="value"
            display="chip"
          />
        </div>
      </div>
      <div class="p-grid p-p-2">
        <label>Maritial status</label>
        <div class="p-col-12 p-pt-3 p-d-flex p-flex-column">
          <MultiSelect
            v-model="filter.marital_status"
            :filter="true"
            :options="selectOptions.marital_statuses"
            option-label="display"
            option-value="value"
            display="chip"
          />
        </div>
      </div>
      <div class="p-grid p-p-2">
        <label>Food preference</label>
        <div class="p-col-12 p-pt-3 p-d-flex p-flex-column">
          <MultiSelect
            v-model="filter.diet"
            :filter="true"
            :options="selectOptions.diets"
            option-label="display"
            option-value="value"
            display="chip"
          />
        </div>
      </div>
      <div class="p-grid p-p-2">
        <label>Father Status</label>
        <div class="p-col-12 p-pt-3 p-d-flex p-flex-column">
          <MultiSelect
            v-model="filter.father_status"
            :filter="true"
            :options="selectOptions.father_statuses"
            option-label="display"
            option-value="value"
            display="chip"
          />
        </div>
      </div>
      <div class="p-grid p-p-2">
        <label>Mother Status</label>
        <div class="p-col-12 p-pt-3 p-d-flex p-flex-column">
          <MultiSelect
            v-model="filter.mother_status"
            :filter="true"
            :options="selectOptions.mother_statuses"
            option-label="display"
            option-value="value"
            display="chip"
          />
        </div>
      </div>
      <div class="p-grid p-p-2">
        <label class="p-pl-0">Income</label>
        <div class="p-col-12 p-d-flex p-p-0">
          <div class="p-col-6">
            <p class="p-col-12">From</p>
            <InputText v-model="filter.income_from" class="p-col-12" />
          </div>
          <div class="p-col-6">
            <p class="p-col-12">To</p>
            <InputText v-model="filter.income_to" class="p-col-12" />
          </div>
        </div>
      </div>
      <div class="p-grid p-p-2">
        <label>Country</label>
        <div class="p-col-12 p-pt-3 p-d-flex p-flex-column">
          <MultiSelect
            v-model="filter.address_country"
            :options="selectOptions.country_names"
            option-label="display"
            option-value="value"
            display="chip"
            :filter="true"
            @show="openCountry = true"
            @hide="setStates"
          />
        </div>
      </div>
      <div class="p-grid p-p-2">
        <label>State</label>
        <div class="p-col-12 p-pt-3 p-d-flex p-flex-column">
          <MultiSelect
            v-model="filter.address_state"
            :show-toggle-all="states.length > 0"
            :options="states"
            option-label="display"
            option-value="value"
            display="chip"
            :filter="states.length > 0"
            :loading="statesLoading"
            :empty-message="
              statesLoading ? 'Loading....' : 'Please select country'
            "
          />
        </div>
      </div>
      <div class="p-grid p-p-2 p-d-flex p-flex-column">
        <label class="p-pl-0">Profile With Photos</label>
        <div class="p-d-flex p-p-0 p-my-2">
          <div class="p-mr-2 p-field-radiobutton">
            <RadioButton
              id="yes"
              v-model="filter.profile_photo"
              :value="true"
            />
            <label for="yes">Yes</label>
          </div>
          <div class="p-field-radiobutton">
            <RadioButton
              id="no"
              v-model="filter.profile_photo"
              :value="false"
            />
            <label for="no">No</label>
          </div>
        </div>
      </div>
    </div>
    <div class="p-grid p-d-flex p-jc-end footer">
      <Button type="submit" class="p-mx-4" label="Search" />
      <Button
        type="button"
        class="p-button-outlined p-ml-1 p-mr-3"
        label="Clear All"
        @click="clearAll"
      />
    </div>
  </form>
</template>
<script>
export default {
  props: {
    options: {
      type: Object,
      default() {
        return {};
      },
    },
    preference: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  emits: ['applyFilter', 'hideDialog'],
  data() {
    return {
      openCountry: false,
      statesLoading: false,
      screenSize: window.innerWidth,
      states: [],
      selectOptions: this.options,
      initialFilterValues: {
        religion: [],
        age_from: null,
        age_to: null,
        height_from: null,
        height_to: null,
        mother_tongue: [],
        highest_qualification: null,
        marital_status: [],
        diet: [],
        father_status: [],
        mother_status: [],
        income_from: null,
        income_to: null,
        profile_photo: false,
        address_country: [],
        address_state: [],
      },
      filter: { ...this.initialFilterValues },
    };
  },
  created() {
    window.addEventListener('resize', () => {
      this.screenSize = window.innerWidth;
    });
    this.filter = this.preference;
    if (this.filter.address_country?.length !== 0) {
      this.fetchStates(this.filter.address_country);
    }
  },
  unmounted() {
    window.removeEventListener('resize', () => {
      this.screenSize = 1200;
    });
  },
  methods: {
    async fetchStates(country) {
      if (country?.length !== 0) {
        this.states.splice(0, this.states?.length);
        this.statesLoading = true;
        let that = this;
        await this.$http
          .get(`/users/country_states?country_names=${country}`)
          .then((res) => {
            that.states = res.data;
            that.statesLoading = false;
            let stateList = that.states.map((item) => item.display);
            let selectedState = that.filter.address_state || [];
            that.filter.address_state = selectedState?.filter((item) =>
              stateList.includes(item)
            );
            that.openCountry = false;
          });
      } else {
        this.filter.address_state = [];
        this.states = [];
      }
    },
    setStates() {
      this.openCountry ? this.fetchStates(this.filter.address_country) : null;
    },
    remodalFilter() {
      let filtr = [
        'mother_tongue',
        'address_state',
        'address_country',
        'religion',
        'father_status',
        'mother_status',
        'marital_status',
        'diet',
        'job_type',
      ];
      let option = [
        'mother_tongues',
        'state_names',
        'country_names',
        'religions',
        'father_statuses',
        'mother_statuses',
        'marital_statuses',
        'diets',
        'jobs',
      ];
      filtr.forEach((element, index) => {
        this.filter[element]?.length ===
        (element === 'address_state'
          ? this.states?.length
          : this.options[option[index]]?.length)
          ? (this.filter[element] = ['all'])
          : null;
      });
    },
    apply() {
      this.remodalFilter();
      this.$emit('applyFilter', this.filter);
      this.close();
    },
    close() {
      this.$emit('hideDialog');
    },
    clearAll() {
      let reset_values = Object.assign({}, this.filter, {
        religion: [],
        age_from: null,
        age_to: null,
        height_from: null,
        height_to: null,
        mother_tongue: [],
        highest_qualification: null,
        marital_status: [],
        diet: [],
        father_status: [],
        mother_status: [],
        income_from: null,
        income_to: null,
        profile_photo: false,
        address_country: [],
        address_state: [],
      });
      this.filter = reset_values;
    },
  },
};
</script>
<style scoped lang="scss">
h4 {
  letter-spacing: -0.015em;
  color: $cardHeaderColor;
}
label {
  letter-spacing: -0.015em;
  color: $labelColor;
}
p {
  letter-spacing: -0.015em;
  color: $labelColor;
}
.close-icon {
  padding-left: 5px;
  cursor: pointer;
}
form {
  padding-bottom: 10vh;
}
.header {
  padding-top: 30px;
}
.footer {
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.1);
  padding-top: 5%;
  padding-bottom: 5%;
  background: $backgroundColor;
  display: block;
  position: fixed;
  bottom: 0px;
  width: 100%;
  margin: auto;
  margin-left: -21px;
}
</style>
<style lang="scss">
.base {
  .p-multiselect {
    color: $inputTextColor !important;
    border: 1px solid $borderColor !important;
    border-radius: 3px;
  }
  .p-inputtext {
    color: $inputTextColor !important;
    border: 1px solid $borderColor !important;
    border-radius: 3px;
  }
}
</style>
