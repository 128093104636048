<template>
  <div class="p-grid all-matches">
    <div class="p-col-12 p-p-0">
      <view-switcher
        :layout="layout"
        :loading="loading"
        :length="profiles.length"
        :show-filter="true"
        @toggleView="toggleView"
        @openFilter="openFilter"
      />
      <div v-if="profiles.length !== 0 || loading">
        <list-view
          v-if="layout === 'list'"
          :profiles="profiles"
          :loading="loading"
          :load-profiles="loadProfiles"
          :profile-number="profileNumber"
          @updateShortlist="shortlistProfile"
          @sendinterest="sendinterest"
        />
        <grid-view
          v-else
          :profiles="profiles"
          :loading="loading"
          :load-profiles="loadProfiles"
          :profile-number="profileNumber"
          @updateShortlist="shortlistProfile"
          @sendinterest="sendinterest"
        />
      </div>
      <div v-else>
        <empty-mails
          :empty-text="'No profiles found for your preference'"
          :img="authUser.gender === 'male' ? '/bride.png' : '/groom.png'"
        />
      </div>
    </div>
  </div>
  <Dialog
    v-model:visible="filterStatus"
    position="left"
    class="filter-dialog"
    :options="selectOptions"
    :show-header="false"
    :closable="true"
    :modal="true"
    :dismissable-mask="true"
    :close-on-escape="true"
    :draggable="false"
  >
    <filter-comp
      :options="selectOptions"
      :preference="filter"
      @hideDialog="closeFilter()"
      @applyFilter="applyFilter"
    />
  </Dialog>
</template>

<script>
import GridView from '@/components/views/GridView.vue';
import ListView from '@/components/views/ListView.vue';
import { mapGetters } from 'vuex';
import FilterComp from '@/components/Filter.vue';
import EmptyMails from '@/components/EmptyMails.vue';
import { profileInterestMixin } from '@/mixins/profileInterestMixin.js';
import { mailboxMixin } from '@/mixins/mailboxMixin';
import ViewSwitcher from '@/utilities/ViewSwitcher.vue';
export default {
  components: {
    GridView,
    ListView,
    FilterComp,
    EmptyMails,
    ViewSwitcher,
  },
  mixins: [profileInterestMixin, mailboxMixin],
  data() {
    return {
      filterStatus: false,
      filter: {},
      reqUrl: 'users',
    };
  },
  computed: {
    ...mapGetters(['authUser', 'selectOptions']),
  },
  created() {
    this.getPreference();
    if (Object.keys(this.selectOptions).length === 0) {
      this.$store.dispatch('getSelectOptions');
    }
  },
  methods: {
    closeFilter() {
      this.filterStatus = false;
    },
    openFilter() {
      this.filterStatus = true;
    },
    getPreference() {
      let that = this;
      this.$http.get(`/user_preference`).then((res) => {
        that.filter = res.data.data ? res.data.data.attributes : {};
        delete that.filter.id;
      });
    },
    async applyFilter(filter) {
      let that = this;
      that.loading = true;
      that.page_no = 1;
      that.eof = false;
      await this.$http
        .patch(`/user_preference`, {
          preference: filter,
        })
        .then(async (res) => {
          that.filter = res.data.data ? res.data.data.attributes : {};
          delete that.filter.id;
          that.eof = false;
          that.fetchProfiles(true);
        })
        .catch(() => {
          that.$moshaToast('Something went wrong', {
            type: 'danger',
            showIcon: true,
            timeout: 2000,
          });
        });
    },
  },
};
</script>

<style scoped lang="scss">
.filter-dialog {
  height: 100vh;
  padding: 0px;
  background: $backgroundColor;
}
</style>
<style lang="scss">
.p-dialog {
  max-height: 100%;
}
.filter-dialog .p-dialog-content {
  width: 27vw;
}
.all-matches {
  margin-top: -12px;
  .filter-button {
    margin-left: 1rem;
    padding-left: 12px;
    padding-right: 5px;
    background: $backgroundColor;
    border-radius: 6px;
  }
  .p-button.p-button-secondary.p-button-outlined {
    background: unset;
    background-color: $backgroundColor;
    color: #363636;
    border: 1px solid $borderColor;
  }
  .p-button.p-button-secondary.p-button-outlined:hover {
    background: unset;
    background-color: $backgroundColor;
    color: $inputTextColor;
    border: 1px solid $borderColor;
  }
  .mobv {
    margin-right: 5px;
  }
  .pi-angle-down {
    padding-left: 70px;
  }
}
@media screen and (min-width: 1000px) and (max-width: 1300px) {
  .filter-dialog .p-dialog-content {
    width: 35vw;
  }
}
@media screen and (max-width: 1000px) and (min-width: 800px) {
  .filter-dialog .p-dialog-content {
    width: 42vw;
  }
}
@media screen and (max-width: 800px) and (min-width: 600px) {
  .filter-dialog .p-dialog-content {
    width: 50vw;
  }
}
@media screen and (max-width: 600px) {
  .filter-dialog .p-dialog-content {
    width: 70vw;
  }
}
</style>
